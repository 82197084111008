<template>
  <div v-if="elements" class="tree-selector" v-click-outside="closeSelector">
    <div class="tree-selector__labels__wrapper" @click="toggleSelector">
      <div class="tree-selector__header">Category</div>
      <span v-if="selectedLabels.length > 0" class="tree-selector__labels">
        {{ selectedLabels[0].trim() }}
        <span v-if="selectedLabels.length > 1"
              class="tree-selector__labels-supplement"> and {{ selectedLabels.length - 1 }} more</span>
      </span>
      <span v-else class="tree-selector__labels">
        Categorized and uncategorized
      </span>
      <IconAngleUp :class="{'tree-selector__show-active': opened}" class="tree-selector__show"/>
      <IconTimesCircle v-show="selected.length !== 0" class="tree-selector__remove-all"
                       @mouseenter.native="toggleMouse(true, 1)"
                       @mouseleave.native="toggleMouse(false, 1)"
                       @click.native="toggleAll(false)"/>
    </div>
    <div v-if="opened" class="tree-selector__content__wrapper"
         @mouseenter="toggleMouse(true, 0)"
         @mouseleave="toggleMouse(false, 0)"
    >
      <div class="tree-selector__content__input-wrapper">
        <input :v-model="searched" class="tree-selector__content__input" placeholder="Type or scroll and choose"
               @input="change"/>
      </div>
      <div class="tree-selector__content__toggle" @click="toggleAll(selected.length === 0)">
        <IconAddDone v-if="selected.length === 0" class="va-icon va-select__option__selected-icon"/>
        <IconRemoveDone v-else class="va-icon va-select__option__selected-icon"/>
        <p>{{ selected.length === 0 ? 'Select all' : 'Unselect all' }}</p>
      </div>
      <div class="tree-selector__content__tree">
        <div class="tree-selector__content__tree-item" v-for="(cat, key) in elements" :key="cat.id">
          <div v-if="isActivated(key) || cat.depth === 0" :style="{'margin-left': `${30 * cat.depth}px`}"
               class="tree-selector__item">
            <div v-if="check(cat)"
                 :class="{'tree-selector__open-active': key !== activated.length - 1 && activated[key + 1] }"
                 class="tree-selector__open"
                 @click="toggle(key)">
              <IconAngleUp/>
            </div>
            <div class="tree-selector__item-text" @click="selectItem(cat)">
              {{ cat.label }}
              <div v-if="isSelected(cat)" class="tree-selector__item-mark">
                <IconCheckMark/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconAddDone from "../ui/icons/IconAddDone.vue"
import IconRemoveDone from "../ui/icons/IconRemoveDone.vue"
import IconAngleUp from "../ui/icons/IconAngleUp";
import IconCheckMark from "../ui/icons/IconCheckMark";
import IconTimesCircle from "@/components/ui/icons/IconTimesCircle";

export default {
  name: "TreeSelector",

  components: {
    IconTimesCircle,
    IconCheckMark,
    IconAngleUp,
    IconAddDone,
    IconRemoveDone
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    isTrends: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      activated: [],
      elements: [],
      selected: [],
      selectedLabels: [],
      searched: '',
      opened: false,
      mouseIn: [false, false]
    }
  },

  created() {
    this.elements = this.reconstructOptions(0, this.options);
    this.activated = []
    this.elements.forEach((category) => this.activated.push(category.depth === 0))
    if (this.isTrends) {
      this.toggleAll(true)
      this.$emit('selectAll')
    }
  },

  watch: {
    options() {
      this.activated = []
      this.elements = this.reconstructOptions(0, this.filteredOptions);
      this.elements.forEach(() => this.activated.push(false))
    },

    searched() {
      this.activated = []
      this.elements = this.reconstructOptions(0, this.filteredOptions);
      this.elements.forEach(() => this.activated.push(this.searched.length > 0))
    }
  },

  computed: {
    filteredOptions() {
      const searchedOptions = []

      const collectOptions = (category) => {
        if (category.label.toLowerCase().includes(this.searched.toLowerCase())) {
          return category
        } else if (category?.children) {
          const toReturn = {...category}
          toReturn.children = []
          category.children.forEach(cat => {
            const returnedCategory = collectOptions(cat)
            if (!!returnedCategory) {
              toReturn.children.push(returnedCategory)
            }
          })

          if (toReturn.children.length === 0)
            return null

          return toReturn
        } else {
          return null
        }
      }

      this.options.forEach(cat => {
        const returnedCategory = collectOptions(cat)
        if (!!returnedCategory) {
          searchedOptions.push(returnedCategory)
        }
      })

      return searchedOptions
    }
  },

  methods: {
    toggleMouse(val, key) {
      this.mouseIn[key] = val
    },

    closeSelector() {
      if (!this.mouseIn[0])
        this.opened = false
    },

    toggleSelector() {
      if (!this.mouseIn[1])
      this.opened = !this.opened
    },

    change({target}) {
      this.searched = target.value
    },

    toggle(key) {
      if (this.activated[key + 1]) {
        for (let i = key + 1; i < this.activated.length; i = i + 1) {
          if (this.elements[i].depth <= this.elements[key].depth)
            break
          this.$set(this.activated, i, false)
        }
      } else {
        for (let i = key + 1; i < this.activated.length; i = i + 1) {
          if (this.elements[i].depth - 1 === this.elements[key].depth)
            this.$set(this.activated, i, true)

          if (this.elements[i].depth <= this.elements[key].depth)
            break
        }
      }
    },

    toggleAll(selectAll) {
      if (selectAll) {
        this.options.forEach(category => {
          this.selectItem(category)
        })
      } else {
        while (this.value.length !== 0) {
          this.$delete(this.value, 0);
        }
        this.selected = []
        this.selectedLabels = []
      }
    },

    isActivated(key) {
      return this.activated[key]
    },

    check(category) {
      return !category.leaf
    },

    reconstructOptions(depth, options) {
      let array = []
      for (let i in options) {
        array.push({
          id: options[i].id,
          label: options[i].label,
          name: options[i].name,
          parentId: options[i].parentId,
          depth: depth,
          leaf: !options[i]?.children
        })

        if (options[i]?.children) {
          array.push(...this.reconstructOptions(depth + 1, options[i].children));
        }
      }

      return array
    },

    selectItem(category) {
      const toCheckCategories = []
      let toggleLeaf = false
      let deleteOrAdd = true
      let found = false

      const collectThis = (category) => {
        if (!category?.children) {
          toCheckCategories.push(category.id)
        } else {
          category.children.forEach(childCategory => collectThis(childCategory))
        }
      }

      const collectToCheck = (categoryToCheck) => {
        if (found) return;
        if (categoryToCheck.id === category.id) {
          collectThis(categoryToCheck)
          found = true
          toggleLeaf = !categoryToCheck?.children
          deleteOrAdd = this.selected.some(id => categoryToCheck.id === id)
        } else if (categoryToCheck?.children) {
          categoryToCheck.children.forEach(childCategory => collectToCheck(childCategory))
        }
      }

      this.options.forEach(category => {
        collectToCheck(category)
      })

      if (toggleLeaf) {
        if (this.value.some(id => id === category.id)) {
          this.$delete(this.value, this.value.findIndex(id => id === category.id))
        } else {
          this.$set(this.value, this.value.length, category.id);
        }
      } else {
        toCheckCategories.forEach(categoryId => {
          if (this.value.some(id => id === categoryId) && deleteOrAdd) {
            this.$delete(this.value, this.value.findIndex(id => id === categoryId))
          } else if (!deleteOrAdd) {
            if (!this.selected.some(id => id === categoryId))
              this.$set(this.value, this.value.length, categoryId);
          }
        })
      }

      this.selected = [];
      this.selectedLabels = []

      const collectSelected = (category) => {
        if (!category?.children) {
          if (this.value.some(id => id === category.id)) {
            this.selectedLabels.push(category.label)
            this.selected.push(category.id);
            return true
          } else {
            return false
          }
        } else {
          const isSelected = category.children.reduce((a, b) => collectSelected(b) & a, true)
          if (isSelected) {
            this.selected.push(category.id);
            return true
          } else {
            return false
          }
        }
      }

      this.options.forEach(category => {
        collectSelected(category)
      })
    },

    isSelected(category) {
      return this.selected.find((id => id === category.id));
    },
  }
}
</script>

<style lang="scss">
.tree-selector {
  color: #000000;
  position: relative;

  &__remove-all {
    position: absolute;
    cursor: pointer;
    right: 30px;
    top: 49%;
    transform-origin: center;
    transform: translateY(-50%) rotate(180deg);
    color: #babfc2;
    height: 16px;
    width: fit-content;
    padding: 0.25rem;

    svg {
      width: 13.59px;
      transform: translateY(-50%);
    }
  }

  &__show {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 50%;
    transform-origin: center;
    transform: translateY(-50%) rotate(180deg);
    color: #babfc2;
    height: 16px;
    width: fit-content;
    padding: 0.25rem;

    &-active {
      transform: translateY(-50%) rotate(0);
    }

    svg {
      width: 10.3px;
      transform: translateY(-50%);
    }
  }

  &__content {
    &__wrapper {
      position: absolute;
      width: 100%;
      top: 100%;
      transform: translate(0, 5px);
      z-index: 100;
      background-color: #ffffff;
      max-height: 300px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
      border-radius: 0.5rem;
    }

    &__input {
      width: 100%;
      border: none;

      &::placeholder {
        color: #8d9ca8;
      }

      &-wrapper {
        width: 100%;
        padding: 13px 16px;
        height: 48px;
      }
    }

    &__toggle {
      cursor: pointer;
      width: 100%;
      display: flex;
      height: 48px;
      align-items: center;
      justify-content: flex-start;
      padding: 0 18px;
      border-bottom: 1px solid #d6dee2;

      .va-select__option__selected-icon {
        margin-left: 0 !important;
      }

      p {
        margin-left: 15px;
      }
    }

    &__tree {
      max-height: 200px;
      overflow-y: auto;

      &-item {
        margin-bottom: 10px;
      }
    }
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding: 0 5px 0 18px;
    margin: 5px 0;

    &-text {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__open {
    cursor: pointer;
    transform: rotate(90deg);
    margin-right: 5px;

    &-active {
      transform: rotate(180deg);
    }
  }

  &__header {
    position: absolute;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-transform: none;
    top: 5px;
    left: 16px;
    color: #8d9ca8;
  }

  &__labels {
    display: block;
    white-space: break-spaces;
    margin-right: 5px;
    max-lines: 1;
    color: #34495e;

    &-supplement {
      cursor: default;
      display: block;
      margin: 2px 0;
      padding: 1px 0;
      color: #bdbdbd;
      font-size: 12px;
      font-weight: 600;
    }

    &__wrapper {
      cursor: pointer;
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      padding: 21px 48px 5px 16px;
      background-color: transparent;
      border: 1px solid #f1f4f8;
      border-radius: 6px;
      min-height: 48px;
    }
  }
}
</style>
