import { isEmpty } from 'lodash';

export const defaultTree = [
  {
    id: 10,
    parentId: null,
    name: "A",
    parentName: "1"
  },
  {
    id: 11,
    parentId: null,
    name: "B",
    parentName: "1"
  },
  {
    id: 21,
    parentId: 10,
    name: "AA",
    parentName: "1"
  },
  {
    id: 22,
    parentId: 10,
    name: "AB",
    parentName: "1"
  },
  {
    id: 31,
    parentId: 21,
    name: "AAA",
    parentName: "1"
  },
  {
    id: 32,
    parentId: 21,
    name: "AAB",
    parentName: "1"
  },
  {
    id: 33,
    parentId: 21,
    name: "AAC",
    parentName: "1"
  },
  {
    id: 55,
    parentId: 33,
    name: "AACA",
    parentName: "1"
  },
  {
    id: 66,
    parentId: 55,
    name: "AACAA",
    parentName: "1"
  },
  {
    id: 77,
    parentId: 66,
    name: "AACAAA",
    parentName: "1"
  },
  {
    id: 88,
    parentId: 77,
    name: "AACAAAA",
    parentName: "1"
  }
];

let defaultArray;


export function listToTree(array){
  array.forEach(i => {
    i.label = i.name
    i.children = array.filter(j => j.parentId === i.id)
    if (!i.children.length) {
      delete i.children
    }
  })

  let parentIds = array.map(i => i.id)
  return JSON.parse(JSON.stringify(array.filter(i => !parentIds.includes(i.parentId))))
}

export function listToTreeLabeled(array) {
  const indexToDell = array.findIndex(elem => elem.id === 1);
  array.splice(indexToDell,1);
  array.sort((a, b) => {
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    return 0;
  });

  array.forEach((i) => {
    if (i.id !== 1) {

      i.label = i.label ? i.label : i.name;
      i.children = array.filter((j) => j.parentId === i.id);
      if (!i.children.length) {
        delete i.children;
      }
    }
  });

  let parentIds = array.map((i) => i.id);
  const result = JSON.parse(
    JSON.stringify(array.filter((i) => !parentIds.includes(i.parentId)))
  );

  return result;
}

export function collectAllIdInTree(data) {
  let array = []
  Object.keys(data).forEach(node => {
    array.push(data[node].id);
  })

  return array;
}

