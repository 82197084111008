<template>
  <vac-card class="filters">
    <form v-if="!onlyLoader" @submit.prevent="onSubmit">
      <div v-if="!isReport" class="row">
        <div class="flex md6 xs12 xl3">
          <VnSelect
            v-model="searchLicenses"
            :disabled="disabled"
            :label="$t('Licenses')"
            :multi-cleanable="true"
            :options="licenses"
            :readonly="readonly"
            :class="{'dropdown-select-error': searchLicensesError}"
            maxHeight="300px"
            multiple
            placeholder="Type or scroll and choose"
            searchable
            textBy="name"
          />
        </div>
        <div class="flex md6 xs12 xl3">
          <VnSelect
            v-model="searchRetailers"
            :disabled="disabled"
            :label="$t('Retailers')"
            :multi-cleanable="true"
            :options="retailers"
            :readonly="readonly"
            :class="{'dropdown-select-error': searchRetailersError}"
            icon="search"
            maxHeight="300px"
            multiple
            placeholder="Type or scroll and choose"
            searchable
            textBy="name"
          />
        </div>
        <div v-if="useContentType" class="flex md6 xs12 xl3">
          <VnSelect
            v-model="searchContentType"
            :disabled="disabled"
            :label="$t('Content type')"
            :options="contentType"
            :readonly="readonly"
            maxHeight="300px"
            textBy="name"
          />
        </div>
        <div v-if="useDatePicker" class="flex md6 xs12 xl3">
          <vn-date-picker
            v-model="range"
            :config="{mode: 'range'}"
            :disabled="disabled"
            :label="$t('Range')"
            class="data-picker__filters"
            :readonly="readonly"
          />
        </div>

        <div v-if="useIntervals" class="flex md6 xs12 xl3">
          <VnSelect
            v-model="searchIntervals"
            :disabled="disabled"
            :label="$t('Intervals')"
            :options="intervals"
            :readonly="readonly"
            maxHeight="300px"
            textBy="name"
          />
        </div>

        <div v-if="useStocks" class="flex md6 xs12 xl3">
          <VnSelect
            v-model="inStock"
            :disabled="disabled"
            :label="$t('Stocks')"
            :options="stocks"
            :readonly="readonly"
            maxHeight="300px"
            textBy="name"
          />
        </div>
        <div v-if="useCategories && fetchedCategories.length" class="flex md12 xs12 xl6">
          <TreeSelector
            v-model="searchCategories"
            :options="getCategories"
            :is-trends="isTrends && !isAdmin"
            @select-all="onSubmit"
            :class="{'tree-select-error': searchCategoriesError}"
          ></TreeSelector>
        </div>
        <div v-if="!disabled && !readonly" class="btns flex md6 xs12 xl12">

          <va-button :style="{marginTop: 0, marginBottom: 0}">Apply</va-button>

          <div class="va-button va-button--outline va-button--normal btn--caps btn--white" tabindex="0"
               @click="onClear">
            <div class="va-button__content">
              <div class="va-button__content__title">Clear all</div>
            </div>
          </div>
        </div>

      </div>

      <div v-else class="column">
        <div class="row">
          <div class="flex full-width">
            <VnSelect
              v-model="searchLicenses"
              :disabled="disabled"
              :label="$t('Licenses')"
              :multi-cleanable="true"
              :options="licenses"
              :readonly="readonly"
              maxHeight="300px"
              multiple
              placeholder="Type or scroll and choose"
              searchable
              textBy="name"
            />
          </div>
          <div class="flex full-width">
            <VnSelect
              v-model="searchRetailers"
              :disabled="disabled"
              :label="$t('Retailers')"
              :multi-cleanable="true"
              :options="retailers"
              :readonly="readonly"
              icon="search"
              maxHeight="300px"
              multiple
              placeholder="Type or scroll and choose"
              searchable
              textBy="name"
            />
          </div>
        </div>
        <div class="row">
          <div v-if="useContentType" class="flex full-width">
            <VnSelect
              v-model="searchContentType"
              :disabled="disabled"
              :label="$t('Content type')"
              :options="contentType"
              :readonly="readonly"
              maxHeight="300px"
              textBy="name"
            />
          </div>
          <div v-if="useCategories && fetchedCategories.length" class="flex">
            <TreeSelector
              v-model="searchCategories"
              :options="getCategories"
            ></TreeSelector>
          </div>

          <div v-if="useDatePicker" class="flex full-width">
            <va-date-picker
              v-model="range"
              :config="{mode: 'range'}"
              :disabled="disabled"
              :label="$t('Range')"
              :readonly="readonly"
            />
          </div>

          <div v-if="useIntervals" class="flex full-width">
            <VnSelect
              v-model="searchIntervals"
              :disabled="disabled"
              :label="$t('Intervals')"
              :options="intervals"
              :readonly="readonly"
              maxHeight="300px"
              textBy="name"
            />
          </div>

          <div v-if="useStocks" class="flex full-width">
            <VnSelect
              v-model="inStock"
              :disabled="disabled"
              :label="$t('Stocks')"
              :options="stocks"
              :readonly="readonly"
              maxHeight="300px"
              textBy="name"
            />
          </div>

          <div v-if="!disabled && !readonly" class="btns flex md6 xs12 xl12">

            <va-button :style="{marginTop: 0, marginBottom: 0}">Apply</va-button>

            <div class="va-button va-button--outline va-button--normal btn--caps btn--white" tabindex="0"
                 @click="onClear">
              <div class="va-button__content">
                <div class="va-button__content__title">Clear all</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <va-progress-bar v-if="loading"
                     :style="{width: '100%', position: 'absolute', bottom: 0, left: 0, backgroundColor: '#ffffff'}"
                     indeterminate></va-progress-bar>

  </vac-card>
</template>

<script>

import HelperUser from "../../services/Helpers/HelperUser";
import {sortAsc} from "../../services/Helpers/HelperObject";
import {refactoringByTimeZone} from "../../services/Helpers/HelperTime";
import {showToastError} from "../../services/Helpers/HelperToast.js"

import API from '../../services/API/API';
import {intervals} from '../../services/API/helpers/getEpackInterval'
import {mapMutations, mapState} from "vuex";

import {listToTree, collectAllIdInTree} from '../../tree'

import TreeSelector from "@/components/filters/TreeSelector";

export default {
  name: 'filters',
  components: {
    TreeSelector,
    VaButton: () => import('vuestic-ui/src/components/vuestic-components/va-button/VaButton'),
    VnSelect: () => import('../ui/vn-select'),
    VnDatePicker: () => import('../ui/VnDatePicker.vue')
  },
  props: {
    onChange: {
      default: null,
      type: Function,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    useDatePicker: {
      default: true,
      type: Boolean,
    },
    useStocks: {
      default: false,
      type: Boolean,
    },
    useContentType: {
      default: true,
      type: Boolean,
    },
    onlyLoader: {
      type: Boolean,
      default: false,
    },
    loadSingle: {
      type: Boolean,
      default: false,
    },
    presetFilters: {},
    licenseRetailers: {},
    userGroup: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    useLicenseRetailers: {
      type: Boolean,
      default: false,
    },
    useCategories: {
      default: false
    },
    loadOnInit: {
      type: Boolean,
      default: false
    },
    useIntervals: {
      type: Boolean,
      default: false
    },
    isReport: {
      type: Boolean,
      default: false
    },
    isTrends: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isAdmin: false,
      fetchedCategories: [],
      categoriesLoading: false,
      intervals: Object.keys(intervals),
      searchIntervals: 'Monthly',
      user: JSON.parse(localStorage.getItem('ttlUserInfo')),
      retailersDefault: [],
      licensesDefault: [],
      licenses: [],
      searchLicenses: [],
      searchLicensesError: false,
      retailers: [],
      searchRetailers: [],
      searchRetailersError: false,
      searchCategories: [],
      searchCategoriesError: false,
      originalCategories: [],
      filterRetailers: [],
      inStock: {
        id: "all",
        name: "Show all",
      },
      range: '',
      stocks: [
        {
          id: "all",
          name: "Show all",
        },
        {
          id: "in-stock",
          name: "In stock",
        },
        {
          id: "not-in-stock",
          name: "Not in stock",
        },
      ],
      contentType: [
        {
          id: 'minisite',
          name: 'Minisite',
        },
        {
          id: 'ar',
          name: 'AR',
        },
      ],
      searchContentType: {
        id: 'minisite',
        name: 'Minisite',
      },
      LEAF_PRIORITY: ""
    };
  },

  created() {
    const {LEAF_PRIORITY} = require("@riophae/vue-treeselect/src/constants");
    this.LEAF_PRIORITY = LEAF_PRIORITY
    const {isAdmin} = HelperUser.getUserLicensesRetailers();
    this.isAdmin = isAdmin
    this.filter();
  },
  computed: {
    ...mapState(['categories']),
    getCategories() {
      if (this.searchLicenses && this.searchLicenses.length) {
        return this.fetchedCategories
      } else {
        return this.categories
      }
    },
  },

  methods: {
    ...mapMutations(['SET_CATEGORIES']),
    async categoriesInit(val) {
      return new Promise((resolve) => {
        if (val.length && this.useCategories) {
          const listOfLicenseKeys = this.user.licenses.filter(item => val.includes(item.name)).map(item => item.id).join(',');
          this.categoriesLoading = true;
          API.APIGet(`${process.env.VUE_APP_API_URL}/category/tree-for-licenses/${listOfLicenseKeys}`, {}, (data) => {
            this.$set(this, 'originalCategories', data);
            this.$set(this, 'fetchedCategories', listToTree(data));
            this.$set(this, 'searchCategories', []);
            this.SET_CATEGORIES(data);
            // if (this.isTrends && !isAdmin) {
            //   this.selectAll()
            // }
            this.categoriesLoading = false;
            resolve(true)
          }, () => {
            this.categoriesLoading = false;
            resolve(true)
          });
        } else {
          this.$set(this, 'searchCategories', []);
          resolve(true)
        }
      })
    },

    async filter() {
      if (this.useLicenseRetailers && this.licenseRetailers?.length || !this.useLicenseRetailers) {
        const {isAdmin} = HelperUser.getUserLicensesRetailers();

        const currentDate = this.getCurrentDate();
        this.range = this.range ? this.range : `${currentDate} to ${currentDate}`;

        let sortUserRetailers = sortAsc(this.userGroup ? this.userGroup.retailers : this.user.retailers, "name");
        let sortUserLicenses = sortAsc(this.userGroup ? this.userGroup.licenses : this.user.licenses, "name");

        let licenses = this.collectArray(sortUserLicenses);
        let retailers = this.collectArray(sortUserRetailers);
        const blackList = [
          'Ozon_Elektrograd',
          'Ozon_Фирменные решения',
          'Ozon_Dyson',
          'Ozon_sberdevaysy',
          'Ozon_Emsa',
          'Ozon_pozitronika-65691',
          'Ozon_Kitfort',
          'Ozon_Braun',
          'Ozon_Haier',
          'Ozon_Delonghi',
          'Ozon_Kaiser',
          'Ozon_Oklick',
          'Ozon_Russkiy-produkt',
          'Ozon_Elikor',
          'Ozon_marvel',
          'Ozon_pozitronika-80646',
          'Ozon_avaks',
          'Ozon_STANLEY_BLACK&DECKER',
          'Ozon_technopark',
          'Ozon_Tecno',
          'Ozon_рм плюс',
          'Ozon_Meffert',
          'Ozon_Официальный магазин Bosch МБТ',
          'Ozon_Официальный реселлер Philips',
          'Ozon_Meridian',
          'Ozon_BBK',
          'OZON_Blanco',
          'Ozon_Rowenta',
          'Ozon_ural-zavod',
          'Ozon_Digma',
          'Ozon_ooo_es_elektrik',
          'Ozon_Panasonic',
          'Ozon_Electrolux КБТ',
          'Ozon_sony-centre',
          'Ozon_Polaris',
          'Ozon_Hansa',
          'Ozon_Onkron',
          'Ozon_Moulinex',
          'Ozon_Whirlpool',
          'Ozon_Steam Toys',
          'Optima_OZON',
          'Ozon_Holodilnik КБТ',
          'Ozon_Интер-Трейд',
          'Ozon_Robert_Bosch',
          'Ozon_Tefal',
          'Ozon_Grohe',
          'OZON',
          'Ozon_ecco',
          'Ozon_arnica-home',
          'Ozon_Weissgauff',
          'Ozon_Onkron_Treyd',
          'Ozon_Официальный магазин Bosch КБТ',
          'Ozon_Onyx Boox',
          'Ozon_Krups',
          'Ozon_Electrolux МБТ',
          'Ozon_Philips',
          'Ozon_sven',
          'Ozon_асбис',
        ]
        if (this.isTrends) {
          retailers = retailers.filter(r => !blackList.includes(r))
        }
        let searchLicenses = !isAdmin || this.userGroup ? licenses : [];
        let searchRetailers = !isAdmin || this.userGroup ? retailers : [];

        if (this.loadSingle) {
          searchLicenses = searchLicenses.slice(0, 1);
        }

        this.licensesDefault = sortUserLicenses;
        this.retailersDefault = sortUserRetailers;
        this.licenses = licenses;
        this.retailers = retailers;
        this.searchRetailers = searchRetailers;
        this.searchLicenses = searchLicenses;
        if (!this.originalCategories?.length && this.useCategories) {
          await this.categoriesInit(this.searchLicenses);
        }

        this.filterRetailers = sortUserRetailers;
        if (this.presetFilters && !this.userGroup) {
          this.searchLicenses = [this.presetFilters];
          this.searchRetailers = sortAsc(this.retailers);
          if (this.loadOnInit) {
            this.$nextTick(() => {
              setTimeout(() => {
                this.onChange(this.collectData());
              }, 1000);
            });
          }
        } else if (this.userGroup) {
          this.onChange(this.collectData());
        }
      }
      if (this.loadOnInit)
        this.onSubmit()
    },

    collectArray(data) {
      return data.map(info => info.name);
    },

    onClear() {
      if (this.disabled || this.readonly) {
        return;
      }

      const currentDate = this.getCurrentDate();
      this.searchRetailers = sortAsc(this.retailers);
      this.searchLicenses = sortAsc(this.licenses);
      this.range = `${currentDate} to ${currentDate}`;

      this.searchContentType = {
        id: 'minisite',
        name: 'Minisite',
      };
      this.onChange(this.collectData());
    },

    onSubmit() {
      if (this.disabled || this.readonly) {
        return;
      }
      this.searchLicensesError = !this.searchLicenses.length;
      this.searchRetailersError = !this.searchRetailers.length;
      if (this.isTrends) {
        this.searchCategoriesError = !this.searchCategories.length
        if (!this.searchLicensesError && !this.searchRetailersError && !this.searchCategoriesError) {

          this.onChange(this.collectData());
        } else {
          this.$emit('filterError')
          showToastError('Please select at least one license/retailer/category', this.$toast)
        }
      } else {
        if (!this.searchLicensesError && !this.searchRetailersError) {

          this.onChange(this.collectData());
        } else {
          this.$emit('filterError')
          showToastError('Please select at least one license/retailer', this.$toast)
        }
      }
    },

    getCurrentDate() {
      return refactoringByTimeZone();
    },

    selectAll() {
      if (this.searchCategories.length > 0) {
        this.searchCategories = []
      } else {
        this.searchCategories = collectAllIdInTree(this.originalCategories)
      }
      this.onSubmit()
    },

    collectData() {
      if (!this.userGroup) {
        const data = {};
        const retailersArray = [];
        const licensesArray = [];
        const categoriesArray = [];

        this.retailersDefault.forEach(retailer => {
          if (this.searchRetailers.includes(retailer.name)) {
            retailersArray.push(retailer.id);
          }
        });

        this.licensesDefault.forEach(license => {
          if (this.searchLicenses.includes(license.name)) {
            licensesArray.push(license.id);
          }
        });

        this.searchCategories.forEach(category => {
          categoriesArray.push(category);
        });

        const currentDate = this.getCurrentDate();
        const range = this.range.split('to');

        if (this.useIntervals)
          data.interval = this.searchIntervals
        data.contentType = this.searchContentType.id;
        data.licenses = licensesArray;
        data.retailers = retailersArray;
        data.categories = categoriesArray;
        data.range = {
          time_local: {
            gte: range[0] ? range[0].trim() : currentDate,
            lte: range[1] ? range[1].trim() : range[0].trim(),
          },
        };

        data.stockStatus = this.inStock.id;

        return data;
      } else {
        const data = {};
        const retailersArray = [];
        const licensesArray = [];
        const categoriesArray = [];

        this.retailersDefault.forEach(retailer => {
          if (this.searchRetailers.includes(retailer.name)) {
            retailersArray.push(retailer.id);
          }
        });

        this.licensesDefault.forEach(license => {
          if (this.searchLicenses.includes(license.name)) {
            licensesArray.push(license.id);
          }
        });
        data.contentType = this.searchContentType.id;
        data.licenses = licensesArray;
        data.retailers = retailersArray;

        return data;
      }
    },
  },
};

</script>

<style lang="scss">
.data-picker__filters {
  font-size: 1rem;
}

.full-width {
  width: 100%;
}

.vac-card__body .row .dropdown-select-error {
  border: 1px solid rgba(219, 69, 69, 1) !important;

  .va-select__label {
    color: rgba(219, 69, 69, 1) !important;
  }

  .va-select__open-icon svg path {
    fill: rgba(219, 69, 69, 1) !important;
  }
}

.filters {
  overflow: visible;

  .vac-card__body {
    overflow: visible;
  }
}

.btns {
  padding: 0;
}

.va-button {
  margin: 0;
}

.tree-select-error > .tree-selector__labels__wrapper {
  border: 1px solid #db4545 !important;
  .tree-selector__header {
    color: #db4545 !important;
  }
}

.vue-treeselect {

  .select-all {
    width: 100%;
    border: none;
    border-bottom: 1px solid #d6dee2;
    background-color: transparent;
    text-align: left;
    height: 47px;
    padding: 0 18px;
    cursor: pointer;
    display: flex;
    align-items: center;

    .va-icon {
      margin-left: unset;
      margin-right: 15px;
    }
  }

  .vue-treeselect__menu {
    width: 100%;
  }

  .vue-treeselect__control {
    border: 1px solid #f1f4f8 !important;
    border-radius: 6px !important;
    height: 48px;
  }

  .vue-treeselect__menu {
    border-radius: 0.5rem;
    top: 4px !important;
    padding: 0;
    box-shadow: 0 2px 8px #0000001a;
    border: 0;
  }

  .vue-treeselect__option {
    height: 45px;
    display: flex;
    align-items: center;
  }

  .vue-treeselect__option--selected .va-icon {
    visibility: visible;
  }

  .vue-treeselect__option--highlight {
    background-color: #ffffff;
  }

  .vue-treeselect__label-container > div {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .vue-treeselect__checkbox-container {
    display: none !important;
  }

  .va-icon {
    visibility: hidden;
  }

  .vue-treeselect__value-remove {
    display: none;
  }

  .vue-treeselect__multi-value-item {
    background-color: white;
  }

  .vue-treeselect__multi-value-label {
    padding: 0;
    color: #34495e;
    font-size: 16px;
    font-family: "Source Sans Pro", Arial, sans-serif;
    line-height: 22px;
  }

  .vue-treeselect__x-container {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: #babfc2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2rem;
    margin: auto;

    svg {
      display: none;
    }

    &::after {
      content: "";
    }
  }

  .vue-treeselect__control-arrow-container {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    right: 0.5rem;
    color: #babfc2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;

    svg {
      display: none;
    }

    &::after {
      content: "";
    }
  }

  .vue-treeselect__placeholder {
    display: block !important;
    font-size: 12px;
    line-height: 16px;
    text-transform: none;
    top: 4px;
    left: 9px;
    color: #8d9ca8 !important;
  }
}

.vue-treeselect .vue-treeselect__multi-value {
  margin-top: 11px;
  padding-left: 11px;
  margin-bottom: 0;
}

.vue-treeselect.vue-treeselect--open .vue-treeselect__control-arrow-container::after {
  transform: rotate(180deg);
}
</style>
